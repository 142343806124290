<template>
    <nav class="navbar bg-white border-bottom sticky-top">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <img :src="require('@/assets/logo.png')" alt="logo" style="height: 40px;">
            </router-link>
            <div class="d-flex align-items-center">
                <router-link class="me-2" to="/notifications" @click="closeNavbar">
                    <i v-if="unreadNotificationsCount > 0" class="fa-solid fa-bell fa-lg text-danger"></i>
                    <i v-else class="fa-regular fa-bell fa-lg"></i>
                </router-link>
                <button class="navbar-toggler border-0" type="button" @click="toggleNavbar">
                    <i class="fa-solid fa-bars fa-lg"></i>
                </button>
            </div>
            <div class="offcanvas offcanvas-start bg-light" :class="{ 'show': navbarOpen }" id="navbarNav">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title"></h5>
                    <button type="button" class="btn-close" @click="closeNavbar" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul class="navbar-nav">
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/" @click="closeNavbar"><i
                                    class="fa-solid fa-house fa-fw me-2"></i>Töölaud</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/calendar" @click="closeNavbar"><i
                                    class="fa-regular fa-calendar-days fa-fw me-2"></i>Kalender</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/realestates" @click="closeNavbar"><i
                                    class="fa-regular fa-building fa-fw me-2"></i>Hooned</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/projects" @click="closeNavbar"><i
                                    class="fa-solid fa-file-invoice fa-fw me-2"></i>Objektikaardid</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/objects" @click="closeNavbar"><i
                                    class="fa-solid fa-walkie-talkie fa-fw me-2"></i>Seadmed</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/stock-items" @click="closeNavbar"><i
                                    class="fa-solid fa-warehouse fa-fw me-2"></i>Ladu</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/tasks-finished" @click="closeNavbar"><i
                                    class="fa-solid fa-list-check fa-fw me-2"></i>Lõpetatud tööd</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/workload" @click="closeNavbar"><i
                                    class="fa-regular fa-clock fa-fw me-2"></i>Tööajaarvestus</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <router-link class="nav-link" to="/scan" @click="closeNavbar"><i
                                    class="fa-solid fa-qrcode fa-fw me-2"></i>QR</router-link>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <a target="_blank" href="https://ussee.sharepoint.com/sites/Manuals/Shared Documents/Forms/AllItems.aspx" class="nav-link" @click="closeNavbar"><i
                                    class="fa-solid fa-book fa-fw me-2"></i>Tehniline dokumentatsioon</a>
                        </li>
                        <li class="nav-item border-bottom py-3">
                            <a href="#" class="nav-link" @click.prevent="logout"><i
                                    class="fa-solid fa-arrow-right-from-bracket fa-fw me-2"></i>Välju</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { msalInstance } from '@/composables/msalConfig';
import axios from '@/services/axios';

export default {
    name: 'MainNavbar',
    data() {
        return {
            navbarOpen: false,
            unreadNotificationsCount: 0,
            interval: null,
            lastFetchTime: null,
            fetchInterval: 60000,
        };
    },
    methods: {
        async logout() {
            const loginMethod = localStorage.getItem('loginMethod');

            if (loginMethod === 'microsoft' && msalInstance) {
                try {
                    await msalInstance.logoutPopup();
                } catch (error) {
                    console.error("MSAL Logout error", error);
                }
            }

            await this.completeLogout();
        },
        toggleNavbar() {
            this.navbarOpen = !this.navbarOpen;
        },
        closeNavbar() {
            this.navbarOpen = false;
        },
        async completeLogout() {
            try {
                await axios.post('/logout');
            } catch (error) {
                console.error("API Logout error", error);
            }

            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('loginMethod');

            this.$router.push('/login');
        },
        async fetchUnreadNotificationsCount() {
            try {
                const response = await axios.get(
                    `/notifications/count/?read=0&user_id=${this.currentUser.id}`
                );
                this.unreadNotificationsCount = response.data.data.count;
                this.lastFetchTime = Date.now(); // Update the last fetch time
            } catch (error) {
                console.error('Error fetching unread notifications count:', error);
            }
        },
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                const timeSinceLastFetch = Date.now() - (this.lastFetchTime || 0);

                if (timeSinceLastFetch >= this.fetchInterval) {
                    // If enough time has passed, fetch immediately
                    this.fetchUnreadNotificationsCount();
                }

                this.startNotificationPolling();
            } else {
                this.stopNotificationPolling();
            }
        },
        startNotificationPolling() {
            if (!this.interval) {
                // Set up the interval if it's not already running
                this.interval = setInterval(this.fetchUnreadNotificationsCount, this.fetchInterval);
            }
        },
        stopNotificationPolling() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
    },
    mounted() {
        this.lastFetchTime = Date.now(); // Initialize the last fetch time
        this.fetchUnreadNotificationsCount(); // Fetch immediately on mount
        this.startNotificationPolling(); // Start polling
    },
    computed: {
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    },
    created() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    beforeUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        this.stopNotificationPolling();
    }
};
</script>


<style scoped></style>