<template>
    <div class="container">
        <span @click="$emit('close')" role="button"><small><i
                    class="fa-solid fa-chevron-left me-2"></i>Tagasi</small></span>
        <h2 class="my-3">{{ type === 'materials' ? 'Lisa materjal ja seadme müük' : 'Lisa aeg või lisatöö' }}</h2>

        <div class="mb-3">
            <label for="resourceSelect" class="form-label"><small>{{ type === 'materials' ? 'Materjal' : 'Töö'
                    }}</small></label>
            <v-select v-if="!itemToEdit" id="resourceSelect" v-model="selectedResource" :options="resources"
                label="name" :reduce="resource => resource.id" class="bg-light"></v-select>
            <div class="form-select bg-secondary-subtle" v-else>{{ itemToEdit.resource_name }}</div>
        </div>

        <div class="mb-3">
            <label for="profileSelect" class="form-label"><small>Töö liik</small></label>
            <select id="profileSelect" v-model="selectedProfile" class="form-select bg-light">
                <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
                    {{ profile.name }}
                </option>
            </select>
        </div>

        <div class="mb-3" v-if="type === 'materials'">
            <label for="descriptionInput" class="form-label"><small>Kirjeldus</small></label>
            <input id="descriptionInput" class="form-control bg-light" v-model="description" placeholder="Kirjeldus"
                @input="fetchSuggestions" list="productList" />
            <datalist id="productList">
                <option v-for="product in suggestions" :key="product.id" :value="product.name"></option>
            </datalist>
        </div>
        <div class="mb-3" v-else>
            <label for="descriptionInput" class="form-label"><small>Kirjeldus</small></label>
            <textarea id="descriptionInput" class="form-control bg-light" v-model="description"
                placeholder="Kirjeldus"></textarea>
        </div>

        <div class="mb-3">
            <label v-if="type === 'materials'" class="form-label">
                <small>Kogus</small>
            </label>

            <div v-if="type === 'materials'">
                <input id="amountInput" class="form-control bg-light"
                    :class="{ 'bg-secondary-subtle': itemToEdit && type === 'materials' }" v-model="amount"
                    placeholder="Kogus" type="text" inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                    @input="validateAmountInput('amount', $event.target.value)"
                    :disabled="itemToEdit && type === 'materials'" />
            </div>

            <div v-else>
                <div class="d-flex">
                    <div class="me-2">
                        <label for="hoursSelect" class="form-label"><small>Tunnid</small></label>
                        <select id="hoursSelect" class="form-select bg-light" v-model.number="selectedHours">
                            <option v-for="hour in hoursOptions" :key="hour" :value="hour">
                                {{ hour }}
                            </option>
                        </select>
                    </div>

                    <div>
                        <label for="minutesSelect" class="form-label"><small>Minutid</small></label>
                        <select id="minutesSelect" class="form-select bg-light" v-model.number="selectedMinutes">
                            <option v-for="minute in minutesOptions" :key="minute" :value="minute">
                                {{ minute }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="type === 'hours'" class="mb-3">
            <label for="pointsInput" class="form-label"><small>Punktid</small></label>
            <input id="pointsInput" class="form-control bg-light" v-model="points" placeholder="Punktid" type="text"
                inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('points', $event.target.value)" />
        </div>

        <div v-if="type === 'hours'" class="mb-3">

            <div>
                <div class="d-flex">
                    <div class="me-2">
                        <label for="distanceHoursSelect" class="form-label"><small>Sõidutunnid</small></label>
                        <select id="distanceHoursSelect" class="form-select bg-light"
                            v-model.number="selectedDistanceHours">
                            <option v-for="hour in hoursOptions" :key="hour" :value="hour">
                                {{ hour }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="distanceMinutesSelect" class="form-label"><small>Minutid</small></label>
                        <select id="distanceMinutesSelect" class="form-select bg-light"
                            v-model.number="selectedDistanceMinutes">
                            <option v-for="minute in minutesOptions" :key="minute" :value="minute">
                                {{ minute }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3" v-if="isCustomResource">
            <label for="priceInput" class="form-label"><small>Hind</small></label>
            <input v-model="price" id="priceInput" class="form-control bg-light" placeholder="Hind" type="text"
                inputmode="decimal" pattern="[0-9]+([,][0-9]{1,2})?"
                @input="validateAmountInput('price', $event.target.value)">
        </div>

        <div class="row">
            <div class="col">
                <div class="mb-3 form-check">
                    <input type="checkbox" v-model="accountingStatusId" class="form-check-input"
                        id="accountingCheckbox">
                    <label class="form-check-label" for="accountingCheckbox">Lisatöö</label>
                </div>
            </div>
            <div class="col text-end">
                <span v-if="this.itemToEdit" role="button" @click="deleteItem" class="text-danger"><small><i
                            class="fa-solid fa-trash-can me-2"></i>Kustuta</small></span>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col">
                <button @click="$emit('close')" class="btn border-1 border-primary text-primary w-100">Loobu</button>
            </div>
            <div class="col">
                <button v-if="!this.itemToEdit" @click="submitItem" class="btn btn-primary w-100">Salvesta</button>
                <button v-if="this.itemToEdit" @click="saveChanges" class="btn btn-primary w-100">Salvesta</button>
            </div>
        </div>

    </div>
</template>

<script>

import axios from '@/services/axios';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        'v-select': VueSelect,
    },
    props: {
        taskId: {
            type: Number,
            required: true
        },
        profileId: {
            type: Number,
            required: true
        },
        priorityId: {
            type: Number,
            required: true
        },
        accountingstatus_id: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        itemToEdit: {
            type: Object,
            default: () => ({})
        },
    },

    data() {
        return {
            selectedResource: null,
            selectedProfile: this.profileId,
            amount: 1,
            distance: null,
            price: null,
            points: null,
            description: null,
            accountingStatusId: (this.accountingstatus_id),
            isCustomResource: false,
            resources: [],
            profiles: [],
            showScanner: false,
            error: null,
            stock_item_id: null,
            selectedHours: 0,
            selectedMinutes: 0,
            selectedDistanceHours: 0,
            selectedDistanceMinutes: 0,
            defaultMaxHours: 10, // Default maximum hours
            hoursOptions: [], // Will be initialized dynamically
            minutesOptions: [0, 15, 30, 45], // Will be initialized dynamically
            suggestions: [],
            lastEmptyInputLength: null, // New property
        };
    },
    watch: {
        selectedResource(newValue) {
            const resource = this.resources.find(r => r.id === newValue);
            if (resource && resource.is_custom_resource) {
                this.isCustomResource = true;
            } else {
                this.isCustomResource = false;
            }
            if (resource && resource.stock_item_id) {
                this.stock_item_id = resource.stock_item_id;
            } else {
                this.stock_item_id = null;
            }
        },
        selectedHours() {
            this.updateAmountFromTime();
        },
        selectedMinutes() {
            this.updateAmountFromTime();
        },
        amount() {
            if (this.type !== 'materials') {
                this.updateTimeFromAmount();
            }
        },
        selectedDistanceHours() {
            this.updateDistanceFromTime();
        },
        selectedDistanceMinutes() {
            this.updateDistanceFromTime();
        },
        distance(newVal) {
            if (newVal != null) {
                this.updateTimeFromDistance();
            }
        },
    },
    mounted() {
        this.fetchResources().then(() => {
            this.fetchProfiles().then(() => {
                if (this.itemToEdit) {
                    this.setEditData();
                }
                if (this.type !== 'materials' && this.amount != null) {
                    this.updateTimeFromAmount();
                }
                if (this.distance != null) {
                    this.updateTimeFromDistance();
                }
            });
        });
    },
    methods: {
        fetchSuggestions() {
            if (this.description.length < 2) {
                this.suggestions = [];
                this.lastEmptyInputLength = null; // Reset when input is too short
                return;
            }
            if (
                this.lastEmptyInputLength !== null &&
                this.description.length > this.lastEmptyInputLength &&
                this.description.startsWith(this.description.slice(0, this.lastEmptyInputLength))
            ) {
                // Do not make API call
                return;
            }
            axios
                .get(`/resources`, {
                    params: {
                        is_material: 1,
                        not_active: 0,
                        search: this.description,
                    },
                })
                .then((response) => {
                    this.suggestions = response.data.data;
                    if (this.suggestions.length === 0) {
                        // No results found; set the last empty input length
                        this.lastEmptyInputLength = this.description.length;
                    } else {
                        // Reset the last empty input length
                        this.lastEmptyInputLength = null;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        updateDistanceFromTime() {
            this.distance = this.selectedDistanceHours + this.selectedDistanceMinutes / 60;
        },

        updateTimeFromDistance() {
            const totalMinutes = Math.round(this.distance * 60);
            this.selectedDistanceHours = Math.floor(totalMinutes / 60);
            this.selectedDistanceMinutes = totalMinutes % 60;
            if (!this.hoursOptions.includes(this.selectedDistanceHours)) {
                const maxHour = Math.max(this.defaultMaxHours, this.selectedDistanceHours);
                this.hoursOptions = Array.from({ length: maxHour + 1 }, (_, i) => i);
            }

            if (!this.minutesOptions.includes(this.selectedDistanceMinutes)) {
                this.minutesOptions.push(this.selectedDistanceMinutes);
                this.minutesOptions.sort((a, b) => a - b);
            }
        },


        updateAmountFromTime() {
            this.amount = this.selectedHours + this.selectedMinutes / 60;
        },

        updateTimeFromAmount() {
            const totalMinutes = Math.round(this.amount * 60);
            this.selectedHours = Math.floor(totalMinutes / 60);
            this.selectedMinutes = totalMinutes % 60;
            if (!this.hoursOptions.includes(this.selectedHours)) {
                const maxHour = Math.max(this.defaultMaxHours, this.selectedHours);
                this.hoursOptions = Array.from({ length: maxHour + 1 }, (_, i) => i);
            }

            if (!this.minutesOptions.includes(this.selectedMinutes)) {
                this.minutesOptions.push(this.selectedMinutes);
                this.minutesOptions.sort((a, b) => a - b);
            }
        },

        validateAmountInput(propertyName, inputValue) {
            let parsedValue = inputValue.replace(/,/g, '.');
            parsedValue = parsedValue.replace(/[^0-9.]/g, '');
            this[propertyName] = parsedValue;
        },
        async fetchResources() {
            if (this.type === 'materials') {
                try {
                    const response = await axios.get(`/users/${this.currentUser.id}/stock-items`);
                    this.resources = response.data.data;
                } catch (error) {
                    console.error('Error fetching stock items:', error);
                }
            } else {
                try {
                    const response = await axios.get(`/resources?is_material=0&not_active=0`);
                    this.resources = response.data.data;
                } catch (error) {
                    console.error('Error fetching resources:', error);
                }
            }
        },
        async fetchProfiles() {
            try {
                const response = await axios.get(`/profiles?not_active=0`);
                this.profiles = response.data.data;
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        },
        setEditData() {
            this.selectedResource = this.itemToEdit.resource_id;
            this.selectedProfile = this.itemToEdit.profile_id;
            this.amount = this.itemToEdit.amount;
            this.points = this.itemToEdit.points;
            this.distance = this.itemToEdit.distance;
            this.price = this.itemToEdit.price;
            this.description = this.itemToEdit.description;
            this.accountingStatusId = this.itemToEdit.accountingstatus_id;

            const resource = this.resources.find(r => r.id === this.selectedResource);
            this.isCustomResource = resource ? resource.is_custom_resource : false;
            this.stock_item_id = resource ? resource.stock_item_id : null;
            if (this.type !== 'materials') {
                this.updateTimeFromAmount();
            }
            if (this.distance != null) {
                this.updateTimeFromDistance();
            }
        },
        async submitItem() {
            console.log(this.stock_item_id);
            try {
                const payload = {
                    resource_id: this.selectedResource,
                    profile_id: this.selectedProfile,
                    amount: parseFloat(this.amount),
                    points: parseFloat(this.points),
                    distance: parseFloat(this.distance),
                    description: this.description,
                    price: this.isCustomResource ? parseFloat(this.price) : null,
                    accountingstatus_id: this.accountingStatusId ? true : false,
                    stock_item_id: this.stock_item_id,
                };

                const response = await axios.post(`/tasks/${this.taskId}/resources`, payload);

                if (response.status === 200) {
                    this.$emit('item-added', response.data.data);
                    this.resetModalFields();
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error saving item:', error);
                this.$root.$refs.globalAlert.showAlert('Lisamine ebaõnnestus', 'danger');
            }
        },
        async saveChanges() {
            const payload = {
                resource_id: this.selectedResource,
                profile_id: this.selectedProfile,
                amount: this.amount,
                points: this.points,
                distance: this.distance,
                description: this.description,
                price: this.isCustomResource ? this.price : null,
                accountingstatus_id: this.accountingStatusId ? true : false,
                stock_item_id: this.stock_item_id,
            };

            try {
                const response = await axios.patch(`/tasks/${this.taskId}/resources/${this.itemToEdit.id}`, payload);
                if (response.status === 200) {
                    this.$emit('item-updated', response.data.data);
                    this.resetModalFields();
                    this.$emit('close');
                }
            } catch (error) {
                console.error('Error updating item:', error);
            }
        },
        async deleteItem() {
            try {
                await axios.delete(`/tasks/${this.taskId}/resources/${this.itemToEdit.id}`);
                this.$emit('item-deleted', this.itemToEdit.id);
                this.$emit('close');
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        },
        resetModalFields() {
            this.selectedResource = null;
            this.selectedProfile = null;
            this.amount = 1;
            this.points = null;
            this.distance = null;
            this.price = null;
            this.description = null;
            this.accountingStatusId = false;
            this.stock_item_id = null;
            this.selectedHours = 0;
            this.selectedMinutes = 0;
            this.selectedDistanceHours = 0;
            this.selectedDistanceMinutes = 0;
        }
    },
    computed: {
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    },
};
</script>

<style scoped></style>
